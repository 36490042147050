<template>
  <div class="cell" :style="style">{{ formatValue }}</div>
</template>

<script>
export default {
  name: "cell",
  props: ['value','width','type'],
  computed:{
    style(){
      let v = {
        width:'64px',
        height:'24px',
        'text-align':'right'
      }
      if (this.width){
        v.width = this.width
      }
      if(this.type === 'header'){
        v['text-align'] = 'center'
      }
      return v
    },
    formatValue(){
      if (typeof(this.value)==="number"){
        if (this.value ===0 )
          return ''
        return new Intl.NumberFormat('en-IN').format(this.value)
      }
      return this.value
    }
  }

}
</script>

<style scoped>
.cell {
  height: 24px;
  border: 1px solid gray;
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: right;
  padding: 0 4px;
}

</style>