<template>
<div class="header-container">
  <cell class="width header-cell" value="" width="150px"></cell>
  <cell class="header-cell" v-for="(v,i) in days" :key="i" :value="v.getDate()+'日'" type="header"></cell>
</div>
</template>

<script>
import cell from "@/views/kanban/cell";
export default {
  components:{cell},
  props:['days'],
  methods:{

  }
}
</script>

<style scoped>
.header-container {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
}
.width {
  width: 48px;
}
.header-cell{
  background: lightgray;
  font-weight: bold;
}
</style>