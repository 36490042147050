<template>
<div class="column">
  <cell :value="rowData.name" width="150px" type="header" class="header-cell"></cell>
  <cell v-for="({value},index) in rowData.values" :key="index" :value="value"></cell>
</div>
</template>

<script>
import cell from "@/views/kanban/cell";
export default {
  name: "tableColumn",
  components:{cell},
  props:['rowData'],
  computed:{
    key(){
      return ''
    },
    cellValue(){
      return []
    }
  }
}
</script>

<style scoped>
.column {
  display: flex;
  flex-flow: row;
}

.header-cell{
  background: lightgray;
  font-weight: bold;
}
</style>