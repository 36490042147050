<template>
  <div>
    <!--    <v-sheet height="500" width="500">-->
    <!--      <v-calendar-->
    <!--          ref="calendar"-->
    <!--          v-model="value"-->
    <!--          :weekdays="weekday"-->
    <!--          :type="type"-->
    <!--          :events="events"-->
    <!--          :event-overlap-mode="mode"-->
    <!--          :event-overlap-threshold="30"-->
    <!--          :event-color="getEventColor"-->
    <!--          @change="getEvents"-->
    <!--      ></v-calendar>-->
    <!--    </v-sheet>-->

    <v-simple-table class="store-table elevation-4" dense fixed-header height="800">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            门店
          </th>
          <th class="text-left">
            启用
          </th>
          <th class="text-left">
            甲方目标
          </th>
          <th class="text-left">
            目标1
          </th>
          <th class="text-left">
            目标2
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="store in storeList"
            :key="store.id"
        >
          <td>{{ store.Name }}</td>
          <td>
            <v-switch
                v-model="store.enabled"
            ></v-switch>
          </td>
          <td class="text-right">
            <v-text-field
                prepend-icon="mdi-bullseye-arrow"
                dense
                hide-details=true
                v-model="store.target0"
                class="target"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
                prepend-icon="mdi-numeric-1-circle-outline"
                dense
                hide-details=true
                v-model="store.target1"
                class="target"
                type="number"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
                prepend-icon="mdi-numeric-2-circle-outline"
                dense
                hide-details=true
                v-model="store.target2"
                class="target"
            ></v-text-field>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div>Size: {{ salesData.length }}</div>
    <div>Orgs: {{ storeList.length }}</div>
    <!--    <div>data: {{ Object.entries(data).length }}</div>-->
    <div>Range: {{ startDate }} - {{ endDate }}</div>

    <div class="table border-box elevation-5">
      <table-header :days="days"></table-header>
      <div class="d-flex flex-column">
        <table-column v-for="(row,index) in data" :key="index" :rowData="row"></table-column>
      </div>
    </div>

    <pre>{{ salesData }}</pre>
  </div>
</template>


<script>
import tableHeader from "@/views/kanban/tableHeader";
import cell from "@/views/kanban/cell";
import tableColumn from "@/views/kanban/tableColumn";
import moment from 'moment'

export default {
  name: "index",
  components: {
    tableHeader, cell, tableColumn
  },
  data() {
    return {
      salesData: [],
      days: [],
      // data: {},
      storeList: []
    }
  },
  created() {
    this.storeList = [
      {"id": 1069, "Name": "测试门店"},
      {"id": 1071, "Name": "黄陂中百店"},
      {"id": 1078, "Name": "黄陂店"}, {
        "id": 1081,
        "Name": "南湖店"
      }, {"id": 1085, "Name": "福客茂店"}, {"id": 1087, "Name": "后湖店"}, {"id": 1089, "Name": "蔡甸新福茂店"}, {
        "id": 1091,
        "Name": "新洲店"
      }, {"id": 1093, "Name": "武胜路店"}, {"id": 1095, "Name": "印象城店"}, {"id": 1097, "Name": "咸宁中百店"}, {
        "id": 1105,
        "Name": "大武汉店"
      }, {"id": 1120, "Name": "光谷店"}, {"id": 1127, "Name": "航空路店"}, {"id": 1136, "Name": "江夏店"}, {
        "id": 1148,
        "Name": "珞狮路奥特莱斯店"
      }, {"id": 1150, "Name": "龙阳店"}, {"id": 1157, "Name": "汉阳店"}, {"id": 1165, "Name": "建八店"}, {
        "id": 1172,
        "Name": "金银潭永旺店"
      }, {"id": 1176, "Name": "阳逻店"}, {"id": 1186, "Name": "武胜路凯德店"}, {"id": 1188, "Name": "唐家墩店"}, {
        "id": 1197,
        "Name": "吴家山店"
      }, {"id": 1208, "Name": "雄楚店"}, {"id": 1214, "Name": "中南路店"}, {"id": 1221, "Name": "仙桃武商店"}, {
        "id": 1223,
        "Name": "武穴城市广场店"
      }, {"id": 1225, "Name": "黄冈紫金城店"}, {"id": 1231, "Name": "鄂州明塘店"}, {"id": 1233, "Name": "鄂州文化宫店"}, {
        "id": 1239,
        "Name": "咸宁咸安店"
      }, {"id": 1241, "Name": "崇阳中百店"}, {"id": 1245, "Name": "嘉鱼中百店"}, {"id": 1247, "Name": "通山老一中店"}, {
        "id": 1249,
        "Name": "温泉店"
      }, {"id": 1251, "Name": "赤壁西湖广场店"}, {"id": 1253, "Name": "孝昌蓝天店"}, {"id": 1255, "Name": "孝感乾坤大道店"}, {
        "id": 1257,
        "Name": "大悟建新街店"
      }, {"id": 1259, "Name": "孝感长征路店"}, {"id": 1261, "Name": "九星店"}, {"id": 1268, "Name": "武商店"}, {
        "id": 1270,
        "Name": "阳新店"
      }, {"id": 1272, "Name": "澜桥店"}, {"id": 1274, "Name": "潜江园林北路店"}, {"id": 1276, "Name": "咸宁金桥店"}, {
        "id": 1278,
        "Name": "欢乐街店"
      }, {"id": 1280, "Name": "孝感保丽店"}, {"id": 1282, "Name": "汉川西门桥店"}, {"id": 1284, "Name": "蕲春中美店"}, {
        "id": 1286,
        "Name": "蕲春二龙宫店"
      }, {"id": 1288, "Name": "黄梅中百店"}, {"id": 1290, "Name": "宝丰路店"}, {"id": 1296, "Name": "工贸总部"}, {
        "id": 1297,
        "Name": "麻城中百店"
      }, {"id": 1303, "Name": "黄冈黄州店"}]
    this.startDate = new Date(2022, 4, 1)
    this.endDate = new Date(2022, 4, 31)
    this.days = this.createData(this.startDate, this.endDate)
    this.salesData = this.getSalesData('武汉泰欣电器股份有限公司', this.startDate, this.endDate)
    // this.setArray()

  },
  methods: {
    findValue(date, storeId) {
      return this.salesData.find(s => {
        return s.store_id === storeId && s.finish_date === moment(date).format('YYYY-MM-DD')
      })
    },
    createData(startDate, endDate) {
      let days = []
      do {
        days.push(startDate)
        startDate = new Date(startDate);
        startDate.setDate(startDate.getDate() + 1)
      }
      while (startDate <= endDate)
      return days
    },
    setArray() {
      let data = {}
      this.storeList.forEach(s => {
        if (!s.enabled) {
          return
        }
        let row = {
          name: s.Name,
          values: []
        }
        this.days.forEach(d => {

          let {qty = 0, amount = 0} = this.findValue(d, s.id) ?? {}
          row.values.push({
            value: amount,
            qty
          })
        })

        data[s.id] = row
      })
      this.data = data
    },
    setValue() {
    },
    getSalesData(partner, startDate, endDate) {
      const data = [
        {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1071,
          "store": "黄陂中百店",
          "finish_date": "2022-05-01",
          "amount": 339.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-01",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-01",
          "amount": 277.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-01",
          "amount": 278.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-01",
          "amount": 774.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1093,
          "store": "武胜路店",
          "finish_date": "2022-05-01",
          "amount": 484.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1095,
          "store": "印象城店",
          "finish_date": "2022-05-01",
          "amount": 207.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-01",
          "amount": 1189.00,
          "qty": 11
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-01",
          "amount": 1371.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-01",
          "amount": 398.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-01",
          "amount": 3659.00,
          "qty": 31
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-01",
          "amount": 1206.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-01",
          "amount": 6267.00,
          "qty": 53
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1165,
          "store": "建八店",
          "finish_date": "2022-05-01",
          "amount": 285.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1172,
          "store": "金银潭永旺店",
          "finish_date": "2022-05-01",
          "amount": 496.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-01",
          "amount": 3909.00,
          "qty": 21
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-01",
          "amount": 2162.00,
          "qty": 18
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-01",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-01",
          "amount": 769.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-01",
          "amount": 357.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1253,
          "store": "孝昌蓝天店",
          "finish_date": "2022-05-01",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-01",
          "amount": 387.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1259,
          "store": "孝感长征路店",
          "finish_date": "2022-05-01",
          "amount": 793.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-01",
          "amount": 4032.00,
          "qty": 28
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1270,
          "store": "阳新店",
          "finish_date": "2022-05-01",
          "amount": 518.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1272,
          "store": "澜桥店",
          "finish_date": "2022-05-01",
          "amount": 110.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1288,
          "store": "黄梅中百店",
          "finish_date": "2022-05-01",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-01",
          "amount": 1945.00,
          "qty": 15
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1297,
          "store": "麻城中百店",
          "finish_date": "2022-05-01",
          "amount": 1215.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-01",
          "amount": 294.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1071,
          "store": "黄陂中百店",
          "finish_date": "2022-05-02",
          "amount": 1327.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-02",
          "amount": 885.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-02",
          "amount": 1564.00,
          "qty": 16
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-02",
          "amount": 1734.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-02",
          "amount": 2104.00,
          "qty": 16
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1091,
          "store": "新洲店",
          "finish_date": "2022-05-02",
          "amount": 615.00,
          "qty": 13
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1093,
          "store": "武胜路店",
          "finish_date": "2022-05-02",
          "amount": 278.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1095,
          "store": "印象城店",
          "finish_date": "2022-05-02",
          "amount": 498.00,
          "qty": 12
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-02",
          "amount": 298.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-02",
          "amount": 4066.00,
          "qty": 24
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-02",
          "amount": 666.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-02",
          "amount": 3518.00,
          "qty": 22
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-02",
          "amount": 3319.00,
          "qty": 21
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-02",
          "amount": 3464.00,
          "qty": 26
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1172,
          "store": "金银潭永旺店",
          "finish_date": "2022-05-02",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-02",
          "amount": 3364.00,
          "qty": 16
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-02",
          "amount": 3113.00,
          "qty": 27
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-02",
          "amount": 1303.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1223,
          "store": "武穴城市广场店",
          "finish_date": "2022-05-02",
          "amount": 769.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-02",
          "amount": 685.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-02",
          "amount": 297.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1241,
          "store": "崇阳中百店",
          "finish_date": "2022-05-02",
          "amount": 646.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1259,
          "store": "孝感长征路店",
          "finish_date": "2022-05-02",
          "amount": 527.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-02",
          "amount": 3535.00,
          "qty": 25
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-02",
          "amount": 1992.00,
          "qty": 24
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1297,
          "store": "麻城中百店",
          "finish_date": "2022-05-02",
          "amount": 707.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-02",
          "amount": 138.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-03",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-03",
          "amount": 545.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-03",
          "amount": 1303.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-03",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-03",
          "amount": 972.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1091,
          "store": "新洲店",
          "finish_date": "2022-05-03",
          "amount": 1072.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1093,
          "store": "武胜路店",
          "finish_date": "2022-05-03",
          "amount": 487.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1095,
          "store": "印象城店",
          "finish_date": "2022-05-03",
          "amount": 166.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-03",
          "amount": 398.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-03",
          "amount": 1831.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-03",
          "amount": 3778.00,
          "qty": 32
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-03",
          "amount": 4114.00,
          "qty": 26
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-03",
          "amount": 945.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-03",
          "amount": 2394.00,
          "qty": 16
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-03",
          "amount": 2285.00,
          "qty": 25
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1172,
          "store": "金银潭永旺店",
          "finish_date": "2022-05-03",
          "amount": 298.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-03",
          "amount": 2653.00,
          "qty": 17
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-03",
          "amount": 1912.00,
          "qty": 18
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-03",
          "amount": 107.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-03",
          "amount": 663.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-03",
          "amount": 308.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-03",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1259,
          "store": "孝感长征路店",
          "finish_date": "2022-05-03",
          "amount": 697.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-03",
          "amount": 2687.00,
          "qty": 23
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1270,
          "store": "阳新店",
          "finish_date": "2022-05-03",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1272,
          "store": "澜桥店",
          "finish_date": "2022-05-03",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1280,
          "store": "孝感保丽店",
          "finish_date": "2022-05-03",
          "amount": 698.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-03",
          "amount": 898.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-03",
          "amount": 158.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-04",
          "amount": 258.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-04",
          "amount": 267.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-04",
          "amount": 426.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-04",
          "amount": 258.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-04",
          "amount": 1090.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1093,
          "store": "武胜路店",
          "finish_date": "2022-05-04",
          "amount": 627.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1095,
          "store": "印象城店",
          "finish_date": "2022-05-04",
          "amount": 117.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-04",
          "amount": 1438.00,
          "qty": 20
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-04",
          "amount": 722.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-04",
          "amount": 2079.00,
          "qty": 21
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-04",
          "amount": 4004.00,
          "qty": 16
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-04",
          "amount": 3312.00,
          "qty": 28
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1165,
          "store": "建八店",
          "finish_date": "2022-05-04",
          "amount": 495.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1172,
          "store": "金银潭永旺店",
          "finish_date": "2022-05-04",
          "amount": 298.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-04",
          "amount": 5027.00,
          "qty": 23
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-04",
          "amount": 1437.00,
          "qty": 13
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-04",
          "amount": 537.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-04",
          "amount": 1330.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-04",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-04",
          "amount": 438.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1241,
          "store": "崇阳中百店",
          "finish_date": "2022-05-04",
          "amount": 419.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-04",
          "amount": 1514.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1259,
          "store": "孝感长征路店",
          "finish_date": "2022-05-04",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-04",
          "amount": 1678.00,
          "qty": 12
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1270,
          "store": "阳新店",
          "finish_date": "2022-05-04",
          "amount": 745.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-04",
          "amount": 1046.00,
          "qty": 12
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-04",
          "amount": 128.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-05",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-05",
          "amount": 1564.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-05",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-05",
          "amount": 495.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1091,
          "store": "新洲店",
          "finish_date": "2022-05-05",
          "amount": 1780.00,
          "qty": 20
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1093,
          "store": "武胜路店",
          "finish_date": "2022-05-05",
          "amount": 158.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-05",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-05",
          "amount": 1899.00,
          "qty": 21
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-05",
          "amount": 1980.00,
          "qty": 20
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-05",
          "amount": 956.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-05",
          "amount": 3229.00,
          "qty": 21
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-05",
          "amount": 1526.00,
          "qty": 14
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1172,
          "store": "金银潭永旺店",
          "finish_date": "2022-05-05",
          "amount": 1360.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-05",
          "amount": 2723.00,
          "qty": 27
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-05",
          "amount": 1355.00,
          "qty": 15
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-05",
          "amount": 1683.00,
          "qty": 17
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-05",
          "amount": 2639.00,
          "qty": 21
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-05",
          "amount": 318.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-05",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-05",
          "amount": 996.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1249,
          "store": "温泉店",
          "finish_date": "2022-05-05",
          "amount": 804.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-05",
          "amount": 287.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1259,
          "store": "孝感长征路店",
          "finish_date": "2022-05-05",
          "amount": 107.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-05",
          "amount": 1409.00,
          "qty": 11
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-06",
          "amount": 445.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-06",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-06",
          "amount": 396.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-06",
          "amount": 1419.00,
          "qty": 11
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-06",
          "amount": 526.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-06",
          "amount": 397.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-06",
          "amount": 178.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-06",
          "amount": 1274.00,
          "qty": 26
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-06",
          "amount": 913.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-06",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-06",
          "amount": 496.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-06",
          "amount": 178.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-07",
          "amount": 496.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-07",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-07",
          "amount": 1237.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-07",
          "amount": 338.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-07",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1091,
          "store": "新洲店",
          "finish_date": "2022-05-07",
          "amount": 544.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1095,
          "store": "印象城店",
          "finish_date": "2022-05-07",
          "amount": 126.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-07",
          "amount": 693.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-07",
          "amount": 1446.00,
          "qty": 14
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-07",
          "amount": 7327.00,
          "qty": 43
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-07",
          "amount": 408.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-07",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-07",
          "amount": 337.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1165,
          "store": "建八店",
          "finish_date": "2022-05-07",
          "amount": 664.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1172,
          "store": "金银潭永旺店",
          "finish_date": "2022-05-07",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-07",
          "amount": 1311.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-07",
          "amount": 426.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-07",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-07",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1241,
          "store": "崇阳中百店",
          "finish_date": "2022-05-07",
          "amount": 419.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1247,
          "store": "通山老一中店",
          "finish_date": "2022-05-07",
          "amount": 596.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-07",
          "amount": 694.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1270,
          "store": "阳新店",
          "finish_date": "2022-05-07",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1276,
          "store": "咸宁金桥店",
          "finish_date": "2022-05-07",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1288,
          "store": "黄梅中百店",
          "finish_date": "2022-05-07",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-07",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-08",
          "amount": 3921.00,
          "qty": 29
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-08",
          "amount": 1653.00,
          "qty": 17
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-08",
          "amount": 298.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1093,
          "store": "武胜路店",
          "finish_date": "2022-05-08",
          "amount": 737.00,
          "qty": 13
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-08",
          "amount": 1331.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-08",
          "amount": 3097.00,
          "qty": 33
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-08",
          "amount": 2154.00,
          "qty": 16
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-08",
          "amount": 1052.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-08",
          "amount": 1654.00,
          "qty": 16
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1172,
          "store": "金银潭永旺店",
          "finish_date": "2022-05-08",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-08",
          "amount": 920.00,
          "qty": 20
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-08",
          "amount": 396.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-08",
          "amount": 548.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-08",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-08",
          "amount": 991.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1276,
          "store": "咸宁金桥店",
          "finish_date": "2022-05-08",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-08",
          "amount": 594.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1091,
          "store": "新洲店",
          "finish_date": "2022-05-09",
          "amount": 196.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-09",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-09",
          "amount": 367.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-09",
          "amount": 1377.00,
          "qty": 13
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-09",
          "amount": 458.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-09",
          "amount": 616.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-09",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-09",
          "amount": 495.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-09",
          "amount": 944.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-09",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-09",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-09",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-09",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-09",
          "amount": 209.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-09",
          "amount": 518.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-10",
          "amount": 308.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-10",
          "amount": 982.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-10",
          "amount": 515.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1095,
          "store": "印象城店",
          "finish_date": "2022-05-10",
          "amount": 1627.00,
          "qty": 13
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-10",
          "amount": 286.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-10",
          "amount": 5795.00,
          "qty": 45
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-10",
          "amount": 348.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-10",
          "amount": 228.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-10",
          "amount": 2091.00,
          "qty": 19
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-10",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-10",
          "amount": 575.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-10",
          "amount": 227.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-10",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-10",
          "amount": 245.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-11",
          "amount": 646.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-11",
          "amount": 498.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1093,
          "store": "武胜路店",
          "finish_date": "2022-05-11",
          "amount": 218.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1095,
          "store": "印象城店",
          "finish_date": "2022-05-11",
          "amount": 101.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-11",
          "amount": 1084.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-11",
          "amount": 596.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-11",
          "amount": 2509.00,
          "qty": 31
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-11",
          "amount": 1020.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-11",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-12",
          "amount": 356.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-12",
          "amount": 776.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-12",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-12",
          "amount": 3374.00,
          "qty": 26
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-12",
          "amount": 637.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-12",
          "amount": 714.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-12",
          "amount": 237.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-12",
          "amount": 794.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-12",
          "amount": 323.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-12",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-12",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-12",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1288,
          "store": "黄梅中百店",
          "finish_date": "2022-05-12",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-13",
          "amount": 705.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-13",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-13",
          "amount": 625.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-13",
          "amount": 1084.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-13",
          "amount": 2463.00,
          "qty": 17
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-13",
          "amount": 368.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-13",
          "amount": 1241.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-13",
          "amount": 1293.00,
          "qty": 17
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1239,
          "store": "咸宁咸安店",
          "finish_date": "2022-05-13",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1247,
          "store": "通山老一中店",
          "finish_date": "2022-05-13",
          "amount": 595.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1249,
          "store": "温泉店",
          "finish_date": "2022-05-13",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1270,
          "store": "阳新店",
          "finish_date": "2022-05-13",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-14",
          "amount": 357.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-14",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-14",
          "amount": 597.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-14",
          "amount": 1123.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1176,
          "store": "阳逻店",
          "finish_date": "2022-05-14",
          "amount": 696.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-14",
          "amount": 801.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-14",
          "amount": 425.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-14",
          "amount": 508.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1247,
          "store": "通山老一中店",
          "finish_date": "2022-05-14",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-14",
          "amount": 1092.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-14",
          "amount": 898.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-15",
          "amount": 990.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-15",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1095,
          "store": "印象城店",
          "finish_date": "2022-05-15",
          "amount": 78.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-15",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-15",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-15",
          "amount": 229.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-15",
          "amount": 536.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1176,
          "store": "阳逻店",
          "finish_date": "2022-05-15",
          "amount": 594.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-15",
          "amount": 662.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-15",
          "amount": 426.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-15",
          "amount": 1698.00,
          "qty": 12
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-15",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-15",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-15",
          "amount": 468.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-15",
          "amount": 396.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1071,
          "store": "黄陂中百店",
          "finish_date": "2022-05-16",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-16",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-16",
          "amount": 78.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-16",
          "amount": 474.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-16",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-16",
          "amount": 1111.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-16",
          "amount": 635.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-16",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-17",
          "amount": 396.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-17",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-17",
          "amount": 19.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-17",
          "amount": 572.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-17",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-17",
          "amount": 169.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-17",
          "amount": 98.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-17",
          "amount": 307.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-17",
          "amount": 436.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-17",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-18",
          "amount": 895.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-18",
          "amount": 458.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-18",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-18",
          "amount": 128.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-18",
          "amount": 523.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-18",
          "amount": 397.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-18",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-18",
          "amount": 229.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-19",
          "amount": 1855.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-19",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-19",
          "amount": 1428.00,
          "qty": 12
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-19",
          "amount": 257.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-19",
          "amount": 327.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-19",
          "amount": 549.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-19",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-19",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1270,
          "store": "阳新店",
          "finish_date": "2022-05-19",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1290,
          "store": "宝丰路店",
          "finish_date": "2022-05-19",
          "amount": 279.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-20",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-20",
          "amount": 753.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-20",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-20",
          "amount": 346.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-20",
          "amount": 398.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-20",
          "amount": 238.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-20",
          "amount": 398.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-20",
          "amount": 308.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-20",
          "amount": 168.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-20",
          "amount": 855.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-20",
          "amount": 1853.00,
          "qty": 17
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-20",
          "amount": 436.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-20",
          "amount": 327.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-20",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1245,
          "store": "嘉鱼中百店",
          "finish_date": "2022-05-20",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-20",
          "amount": 339.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-20",
          "amount": 297.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-21",
          "amount": 546.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-21",
          "amount": 259.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-21",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-21",
          "amount": 396.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-21",
          "amount": 477.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-21",
          "amount": 498.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-21",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-21",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-21",
          "amount": 1158.00,
          "qty": 12
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-21",
          "amount": 1490.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-21",
          "amount": 327.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-21",
          "amount": 725.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-21",
          "amount": 228.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-21",
          "amount": 895.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-22",
          "amount": 396.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-22",
          "amount": 596.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-22",
          "amount": 497.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1089,
          "store": "蔡甸新福茂店",
          "finish_date": "2022-05-22",
          "amount": 694.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-22",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-22",
          "amount": 605.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-22",
          "amount": 1021.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-22",
          "amount": 1423.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-22",
          "amount": 1331.00,
          "qty": 19
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-22",
          "amount": 2064.00,
          "qty": 16
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-22",
          "amount": 397.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-22",
          "amount": 646.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-22",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1288,
          "store": "黄梅中百店",
          "finish_date": "2022-05-22",
          "amount": 595.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-23",
          "amount": 695.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-23",
          "amount": 545.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-23",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-23",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-23",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-23",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1172,
          "store": "金银潭永旺店",
          "finish_date": "2022-05-23",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-23",
          "amount": 98.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1245,
          "store": "嘉鱼中百店",
          "finish_date": "2022-05-23",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-23",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-24",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1087,
          "store": "后湖店",
          "finish_date": "2022-05-24",
          "amount": 148.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-24",
          "amount": 458.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-24",
          "amount": 78.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-24",
          "amount": 526.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-24",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1165,
          "store": "建八店",
          "finish_date": "2022-05-24",
          "amount": 295.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-24",
          "amount": 294.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-24",
          "amount": 998.00,
          "qty": 12
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-24",
          "amount": 138.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-24",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1069,
          "store": "测试门店",
          "finish_date": "2022-05-25",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-25",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-25",
          "amount": 228.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-25",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-25",
          "amount": 297.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-25",
          "amount": 96.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-25",
          "amount": 727.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-25",
          "amount": 406.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-25",
          "amount": 297.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-26",
          "amount": 4410.00,
          "qty": 30
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-26",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-26",
          "amount": 990.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-26",
          "amount": 549.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-26",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-26",
          "amount": 465.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-26",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-26",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-27",
          "amount": 635.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-27",
          "amount": 359.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-27",
          "amount": 267.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-27",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-27",
          "amount": 457.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-27",
          "amount": 1958.00,
          "qty": 22
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1208,
          "store": "雄楚店",
          "finish_date": "2022-05-27",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-27",
          "amount": 769.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-27",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-28",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-28",
          "amount": 594.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-28",
          "amount": 2213.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1097,
          "store": "咸宁中百店",
          "finish_date": "2022-05-28",
          "amount": 229.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-28",
          "amount": 824.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-28",
          "amount": 288.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-28",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1157,
          "store": "汉阳店",
          "finish_date": "2022-05-28",
          "amount": 2183.00,
          "qty": 17
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-28",
          "amount": 6384.00,
          "qty": 36
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1197,
          "store": "吴家山店",
          "finish_date": "2022-05-28",
          "amount": 1487.00,
          "qty": 13
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1214,
          "store": "中南路店",
          "finish_date": "2022-05-28",
          "amount": 475.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-28",
          "amount": 468.00,
          "qty": 2
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1231,
          "store": "鄂州明塘店",
          "finish_date": "2022-05-28",
          "amount": 585.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-28",
          "amount": 169.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1245,
          "store": "嘉鱼中百店",
          "finish_date": "2022-05-28",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-28",
          "amount": 825.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1257,
          "store": "大悟建新街店",
          "finish_date": "2022-05-28",
          "amount": 597.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1259,
          "store": "孝感长征路店",
          "finish_date": "2022-05-28",
          "amount": 1026.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-28",
          "amount": 1092.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1280,
          "store": "孝感保丽店",
          "finish_date": "2022-05-28",
          "amount": 245.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1303,
          "store": "黄冈黄州店",
          "finish_date": "2022-05-28",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1078,
          "store": "黄陂店",
          "finish_date": "2022-05-29",
          "amount": 477.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1081,
          "store": "南湖店",
          "finish_date": "2022-05-29",
          "amount": 714.00,
          "qty": 6
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1085,
          "store": "福客茂店",
          "finish_date": "2022-05-29",
          "amount": 446.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1105,
          "store": "大武汉店",
          "finish_date": "2022-05-29",
          "amount": 404.00,
          "qty": 10
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1120,
          "store": "光谷店",
          "finish_date": "2022-05-29",
          "amount": 495.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1127,
          "store": "航空路店",
          "finish_date": "2022-05-29",
          "amount": 505.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1136,
          "store": "江夏店",
          "finish_date": "2022-05-29",
          "amount": 992.00,
          "qty": 8
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1150,
          "store": "龙阳店",
          "finish_date": "2022-05-29",
          "amount": 327.00,
          "qty": 3
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1165,
          "store": "建八店",
          "finish_date": "2022-05-29",
          "amount": 991.00,
          "qty": 9
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1176,
          "store": "阳逻店",
          "finish_date": "2022-05-29",
          "amount": 1093.00,
          "qty": 7
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1188,
          "store": "唐家墩店",
          "finish_date": "2022-05-29",
          "amount": 5210.00,
          "qty": 20
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1225,
          "store": "黄冈紫金城店",
          "finish_date": "2022-05-29",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1233,
          "store": "鄂州文化宫店",
          "finish_date": "2022-05-29",
          "amount": 645.00,
          "qty": 5
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1255,
          "store": "孝感乾坤大道店",
          "finish_date": "2022-05-29",
          "amount": 196.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1259,
          "store": "孝感长征路店",
          "finish_date": "2022-05-29",
          "amount": 456.00,
          "qty": 4
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1261,
          "store": "九星店",
          "finish_date": "2022-05-29",
          "amount": 2502.00,
          "qty": 18
        }, {
          "partner": "武汉泰欣电器股份有限公司",
          "store_id": 1276,
          "store": "咸宁金桥店",
          "finish_date": "2022-05-29",
          "amount": 617.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-01",
          "amount": 818.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1322,
          "store": "渝北海莹",
          "finish_date": "2022-05-01",
          "amount": 2863.00,
          "qty": 7
        }, {
          "partner": "重百售后",
          "store_id": 1323,
          "store": "渝北子轩",
          "finish_date": "2022-05-01",
          "amount": 2146.00,
          "qty": 4
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-01",
          "amount": 1408.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-01",
          "amount": 1438.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-01",
          "amount": 339.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-01",
          "amount": 1796.00,
          "qty": 4
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-02",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-02",
          "amount": 2324.00,
          "qty": 6
        }, {
          "partner": "重百售后",
          "store_id": 1321,
          "store": "永川区志宏家电维修部",
          "finish_date": "2022-05-02",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1322,
          "store": "渝北海莹",
          "finish_date": "2022-05-02",
          "amount": 2099.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1323,
          "store": "渝北子轩",
          "finish_date": "2022-05-02",
          "amount": 1557.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-02",
          "amount": 868.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-02",
          "amount": 2925.00,
          "qty": 5
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-02",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-02",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1333,
          "store": "重庆钰真电子有限公司",
          "finish_date": "2022-05-02",
          "amount": 559.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-02",
          "amount": 428.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1339,
          "store": "奉节县虹福家电维修部",
          "finish_date": "2022-05-02",
          "amount": 729.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-02",
          "amount": 728.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1429,
          "store": "长寿红伟家电维修中心",
          "finish_date": "2022-05-02",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-03",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-03",
          "amount": 706.00,
          "qty": 4
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-03",
          "amount": 498.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-03",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-03",
          "amount": 1067.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-03",
          "amount": 3235.00,
          "qty": 5
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-03",
          "amount": 838.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-03",
          "amount": 2435.00,
          "qty": 5
        }, {
          "partner": "重百售后",
          "store_id": 1429,
          "store": "长寿红伟家电维修中心",
          "finish_date": "2022-05-03",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-04",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-04",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1317,
          "store": "綦江谢军家电维修中心",
          "finish_date": "2022-05-04",
          "amount": 898.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-04",
          "amount": 3082.00,
          "qty": 8
        }, {
          "partner": "重百售后",
          "store_id": 1322,
          "store": "渝北海莹",
          "finish_date": "2022-05-04",
          "amount": 2128.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1323,
          "store": "渝北子轩",
          "finish_date": "2022-05-04",
          "amount": 1477.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-04",
          "amount": 938.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-04",
          "amount": 5646.00,
          "qty": 4
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-04",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-04",
          "amount": 609.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1429,
          "store": "长寿红伟家电维修中心",
          "finish_date": "2022-05-04",
          "amount": 339.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-05",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-05",
          "amount": 3065.00,
          "qty": 5
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-05",
          "amount": 1645.00,
          "qty": 5
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-05",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-05",
          "amount": 2868.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1333,
          "store": "重庆钰真电子有限公司",
          "finish_date": "2022-05-05",
          "amount": 1079.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-05",
          "amount": 838.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-05",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1338,
          "store": "垫江县道怀电器维修部",
          "finish_date": "2022-05-05",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-05",
          "amount": 678.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1343,
          "store": "永川刘冰家电维修部",
          "finish_date": "2022-05-05",
          "amount": 79.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-06",
          "amount": 1637.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-06",
          "amount": 578.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-06",
          "amount": 729.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-06",
          "amount": 2367.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-06",
          "amount": 899.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-06",
          "amount": 559.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-06",
          "amount": 688.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-06",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-07",
          "amount": 559.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-07",
          "amount": 878.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-07",
          "amount": 4700.00,
          "qty": 10
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-07",
          "amount": 959.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-07",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-07",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-07",
          "amount": 1517.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-08",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-08",
          "amount": 609.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-08",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1321,
          "store": "永川区志宏家电维修部",
          "finish_date": "2022-05-08",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-08",
          "amount": 7097.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-08",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1333,
          "store": "重庆钰真电子有限公司",
          "finish_date": "2022-05-08",
          "amount": 559.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-08",
          "amount": 598.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-09",
          "amount": 738.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1321,
          "store": "永川区志宏家电维修部",
          "finish_date": "2022-05-09",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-09",
          "amount": 1288.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-09",
          "amount": 1238.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-09",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-09",
          "amount": 1658.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-10",
          "amount": 1026.00,
          "qty": 4
        }, {
          "partner": "重百售后",
          "store_id": 1321,
          "store": "永川区志宏家电维修部",
          "finish_date": "2022-05-10",
          "amount": 758.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-10",
          "amount": 1668.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-10",
          "amount": 2007.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-10",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1343,
          "store": "永川刘冰家电维修部",
          "finish_date": "2022-05-10",
          "amount": 779.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-11",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-11",
          "amount": 918.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1322,
          "store": "渝北海莹",
          "finish_date": "2022-05-11",
          "amount": 1966.00,
          "qty": 4
        }, {
          "partner": "重百售后",
          "store_id": 1323,
          "store": "渝北子轩",
          "finish_date": "2022-05-11",
          "amount": 1548.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-11",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-11",
          "amount": 729.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-11",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-11",
          "amount": 787.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-12",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-12",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-12",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-12",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-12",
          "amount": 568.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-12",
          "amount": 358.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-13",
          "amount": 1857.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-13",
          "amount": 79.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-13",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-13",
          "amount": 1627.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-14",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-14",
          "amount": 757.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-14",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-14",
          "amount": 1927.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1339,
          "store": "奉节县虹福家电维修部",
          "finish_date": "2022-05-14",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-15",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-15",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-15",
          "amount": 878.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-15",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-15",
          "amount": 1399.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-15",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-15",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1341,
          "store": "重庆潼南瑞博家用电器经营部",
          "finish_date": "2022-05-15",
          "amount": 829.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-16",
          "amount": 718.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1321,
          "store": "永川区志宏家电维修部",
          "finish_date": "2022-05-16",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-16",
          "amount": 729.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-16",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-16",
          "amount": 779.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-16",
          "amount": 279.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-16",
          "amount": 1328.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-17",
          "amount": 499.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-17",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-17",
          "amount": 678.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-17",
          "amount": 779.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-17",
          "amount": 609.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-17",
          "amount": 449.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-17",
          "amount": 917.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-17",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1429,
          "store": "长寿红伟家电维修中心",
          "finish_date": "2022-05-17",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1317,
          "store": "綦江谢军家电维修中心",
          "finish_date": "2022-05-18",
          "amount": 499.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-18",
          "amount": 378.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-18",
          "amount": 729.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-18",
          "amount": 499.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-18",
          "amount": 729.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-18",
          "amount": 609.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-18",
          "amount": 168.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-18",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-18",
          "amount": 1187.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1429,
          "store": "长寿红伟家电维修中心",
          "finish_date": "2022-05-18",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-19",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-19",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1317,
          "store": "綦江谢军家电维修中心",
          "finish_date": "2022-05-19",
          "amount": 449.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-19",
          "amount": 728.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-19",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-19",
          "amount": 1099.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-19",
          "amount": 528.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-19",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1317,
          "store": "綦江谢军家电维修中心",
          "finish_date": "2022-05-20",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-20",
          "amount": 757.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1321,
          "store": "永川区志宏家电维修部",
          "finish_date": "2022-05-20",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-20",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-20",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-20",
          "amount": 458.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1430,
          "store": "云阳博远",
          "finish_date": "2022-05-20",
          "amount": 669.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-21",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-21",
          "amount": 837.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-21",
          "amount": 1008.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-21",
          "amount": 1358.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-21",
          "amount": 1226.00,
          "qty": 4
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-21",
          "amount": 438.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1339,
          "store": "奉节县虹福家电维修部",
          "finish_date": "2022-05-21",
          "amount": 1399.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-21",
          "amount": 569.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1317,
          "store": "綦江谢军家电维修中心",
          "finish_date": "2022-05-22",
          "amount": 458.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-22",
          "amount": 1534.00,
          "qty": 6
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-22",
          "amount": 938.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-22",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-22",
          "amount": 758.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-22",
          "amount": 728.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1341,
          "store": "重庆潼南瑞博家用电器经营部",
          "finish_date": "2022-05-22",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-23",
          "amount": 339.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-23",
          "amount": 1673.00,
          "qty": 7
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-23",
          "amount": 618.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-23",
          "amount": 1828.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-23",
          "amount": 559.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1333,
          "store": "重庆钰真电子有限公司",
          "finish_date": "2022-05-23",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-23",
          "amount": 339.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-23",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1338,
          "store": "垫江县道怀电器维修部",
          "finish_date": "2022-05-23",
          "amount": 499.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-23",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-24",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-24",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-24",
          "amount": 2908.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-24",
          "amount": 318.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-24",
          "amount": 109.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1333,
          "store": "重庆钰真电子有限公司",
          "finish_date": "2022-05-24",
          "amount": 1099.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-24",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-25",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1322,
          "store": "渝北海莹",
          "finish_date": "2022-05-25",
          "amount": 2978.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-25",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-25",
          "amount": 11932.00,
          "qty": 8
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-25",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-25",
          "amount": 918.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-25",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-25",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-26",
          "amount": 1126.00,
          "qty": 4
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-26",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-26",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-26",
          "amount": 517.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1333,
          "store": "重庆钰真电子有限公司",
          "finish_date": "2022-05-26",
          "amount": 1099.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-26",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1335,
          "store": "测试配送站",
          "finish_date": "2022-05-26",
          "amount": 0.05,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1341,
          "store": "重庆潼南瑞博家用电器经营部",
          "finish_date": "2022-05-26",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-27",
          "amount": 189.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1317,
          "store": "綦江谢军家电维修中心",
          "finish_date": "2022-05-27",
          "amount": 908.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-27",
          "amount": 538.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1324,
          "store": "重庆寒森电器维修服务部",
          "finish_date": "2022-05-27",
          "amount": 918.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-27",
          "amount": 528.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-27",
          "amount": 269.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-28",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1317,
          "store": "綦江谢军家电维修中心",
          "finish_date": "2022-05-28",
          "amount": 609.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-28",
          "amount": 568.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-28",
          "amount": 309.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-28",
          "amount": 6077.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1328,
          "store": "重庆市大渡口区伍友家服务社",
          "finish_date": "2022-05-28",
          "amount": 488.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-28",
          "amount": 168.00,
          "qty": 2
        }, {
          "partner": "重百售后",
          "store_id": 1334,
          "store": "重庆长旺隆电器有限公司",
          "finish_date": "2022-05-28",
          "amount": 559.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1337,
          "store": "重庆市广虹电器维修服务部",
          "finish_date": "2022-05-28",
          "amount": 729.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1340,
          "store": "涪陵区恒捷家电维修服务部",
          "finish_date": "2022-05-28",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1315,
          "store": "四川快益点电器服务连锁有限公司重庆分公司",
          "finish_date": "2022-05-29",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1316,
          "store": "南岸区渝海家电安装维修部",
          "finish_date": "2022-05-29",
          "amount": 2687.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1319,
          "store": "万州宏鑫",
          "finish_date": "2022-05-29",
          "amount": 1777.00,
          "qty": 3
        }, {
          "partner": "重百售后",
          "store_id": 1326,
          "store": "重庆平合电器",
          "finish_date": "2022-05-29",
          "amount": 609.00,
          "qty": 1
        }, {
          "partner": "重百售后",
          "store_id": 1327,
          "store": "重庆商维电器有限公司",
          "finish_date": "2022-05-29",
          "amount": 5871.00,
          "qty": 9
        }, {
          "partner": "重百售后",
          "store_id": 1332,
          "store": "重庆湘桐商贸有限公司",
          "finish_date": "2022-05-29",
          "amount": 1894.00,
          "qty": 6
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-01",
          "amount": 728.00,
          "qty": 2
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-02",
          "amount": 2714.00,
          "qty": 6
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-03",
          "amount": 1246.00,
          "qty": 4
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-04",
          "amount": 3092.00,
          "qty": 8
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-05",
          "amount": 1744.00,
          "qty": 6
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-06",
          "amount": 178.00,
          "qty": 2
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-07",
          "amount": 259.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-09",
          "amount": 538.00,
          "qty": 2
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-10",
          "amount": 259.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-11",
          "amount": 977.00,
          "qty": 3
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-13",
          "amount": 319.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-14",
          "amount": 467.00,
          "qty": 3
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-15",
          "amount": 957.00,
          "qty": 3
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-16",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-17",
          "amount": 397.00,
          "qty": 3
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-18",
          "amount": 998.00,
          "qty": 2
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-20",
          "amount": 1637.00,
          "qty": 3
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-21",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-22",
          "amount": 278.00,
          "qty": 2
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-23",
          "amount": 1257.00,
          "qty": 3
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-24",
          "amount": 617.00,
          "qty": 3
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-25",
          "amount": 389.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-26",
          "amount": 519.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-27",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-28",
          "amount": 899.00,
          "qty": 1
        }, {
          "partner": "工贸配送",
          "store_id": 1345,
          "store": "楚蕴恒泰",
          "finish_date": "2022-05-29",
          "amount": 2224.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-01",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-01",
          "amount": 714.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-01",
          "amount": 1809.00,
          "qty": 11
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-01",
          "amount": 337.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-01",
          "amount": 1863.00,
          "qty": 7
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-01",
          "amount": 2241.00,
          "qty": 9
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-01",
          "amount": 347.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-01",
          "amount": 268.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-01",
          "amount": 474.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-01",
          "amount": 1362.00,
          "qty": 8
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-01",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-01",
          "amount": 528.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1369,
          "store": "丰都商场",
          "finish_date": "2022-05-01",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-01",
          "amount": 754.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-01",
          "amount": 525.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-01",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-01",
          "amount": 1378.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1388,
          "store": "南川商都",
          "finish_date": "2022-05-01",
          "amount": 1227.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-01",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-02",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-02",
          "amount": 974.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-02",
          "amount": 1212.00,
          "qty": 8
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-02",
          "amount": 1687.00,
          "qty": 13
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-02",
          "amount": 5214.00,
          "qty": 26
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-02",
          "amount": 534.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-02",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-02",
          "amount": 674.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-02",
          "amount": 2535.00,
          "qty": 15
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-02",
          "amount": 1810.00,
          "qty": 10
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-02",
          "amount": 767.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1363,
          "store": "弹子石商场",
          "finish_date": "2022-05-02",
          "amount": 699.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-02",
          "amount": 856.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1365,
          "store": "大坪商场",
          "finish_date": "2022-05-02",
          "amount": 698.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-02",
          "amount": 1113.00,
          "qty": 7
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-02",
          "amount": 209.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-02",
          "amount": 2409.00,
          "qty": 11
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-02",
          "amount": 2123.00,
          "qty": 7
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-02",
          "amount": 1077.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1379,
          "store": "云阳店",
          "finish_date": "2022-05-02",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-02",
          "amount": 425.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-02",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-02",
          "amount": 5218.00,
          "qty": 12
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-02",
          "amount": 628.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1388,
          "store": "南川商都",
          "finish_date": "2022-05-02",
          "amount": 765.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-03",
          "amount": 218.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-03",
          "amount": 686.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-03",
          "amount": 2010.00,
          "qty": 10
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-03",
          "amount": 625.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-03",
          "amount": 3550.00,
          "qty": 20
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-03",
          "amount": 287.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-03",
          "amount": 999.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-03",
          "amount": 1878.00,
          "qty": 12
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-03",
          "amount": 1171.00,
          "qty": 9
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-03",
          "amount": 218.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1361,
          "store": "南坪商场",
          "finish_date": "2022-05-03",
          "amount": 1323.00,
          "qty": 7
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-03",
          "amount": 1376.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-03",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1365,
          "store": "大坪商场",
          "finish_date": "2022-05-03",
          "amount": 279.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-03",
          "amount": 1482.00,
          "qty": 8
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-03",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-03",
          "amount": 408.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-03",
          "amount": 108.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1372,
          "store": "江南商都",
          "finish_date": "2022-05-03",
          "amount": 338.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-03",
          "amount": 563.00,
          "qty": 7
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-03",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-03",
          "amount": 528.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1378,
          "store": "垫江店",
          "finish_date": "2022-05-03",
          "amount": 387.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-03",
          "amount": 347.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-03",
          "amount": 1714.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-03",
          "amount": 707.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-03",
          "amount": 785.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-03",
          "amount": 217.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-04",
          "amount": 295.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-04",
          "amount": 347.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-04",
          "amount": 824.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-04",
          "amount": 2114.00,
          "qty": 16
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-04",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-04",
          "amount": 957.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-04",
          "amount": 854.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-04",
          "amount": 724.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-04",
          "amount": 1621.00,
          "qty": 9
        }, {
          "partner": "重百零售",
          "store_id": 1361,
          "store": "南坪商场",
          "finish_date": "2022-05-04",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-04",
          "amount": 797.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-04",
          "amount": 795.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-04",
          "amount": 599.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-04",
          "amount": 1807.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-04",
          "amount": 207.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1372,
          "store": "江南商都",
          "finish_date": "2022-05-04",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-04",
          "amount": 654.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-04",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-04",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-04",
          "amount": 1647.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-04",
          "amount": 1231.00,
          "qty": 9
        }, {
          "partner": "重百零售",
          "store_id": 1381,
          "store": "巴南商都家电馆",
          "finish_date": "2022-05-04",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-04",
          "amount": 507.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-04",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-04",
          "amount": 1057.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1386,
          "store": "大渡口商都",
          "finish_date": "2022-05-04",
          "amount": 209.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-04",
          "amount": 169.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1391,
          "store": "铜梁商场",
          "finish_date": "2022-05-04",
          "amount": 1128.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-05",
          "amount": 318.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-05",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-05",
          "amount": 736.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-05",
          "amount": 358.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-05",
          "amount": 1880.00,
          "qty": 20
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-05",
          "amount": 1280.00,
          "qty": 10
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-05",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-05",
          "amount": 1786.00,
          "qty": 14
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-05",
          "amount": 519.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-05",
          "amount": 428.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-05",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-05",
          "amount": 526.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-05",
          "amount": 358.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-05",
          "amount": 778.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-05",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-05",
          "amount": 1011.00,
          "qty": 9
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-05",
          "amount": 746.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-05",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-05",
          "amount": 418.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1386,
          "store": "大渡口商都",
          "finish_date": "2022-05-05",
          "amount": 459.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1388,
          "store": "南川商都",
          "finish_date": "2022-05-05",
          "amount": 648.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-06",
          "amount": 78.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-06",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-06",
          "amount": 402.00,
          "qty": 8
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-06",
          "amount": 377.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-06",
          "amount": 3271.00,
          "qty": 49
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-06",
          "amount": 187.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-06",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-06",
          "amount": 428.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-06",
          "amount": 119.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1363,
          "store": "弹子石商场",
          "finish_date": "2022-05-06",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1365,
          "store": "大坪商场",
          "finish_date": "2022-05-06",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-06",
          "amount": 508.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-06",
          "amount": 797.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-06",
          "amount": 499.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-06",
          "amount": 913.00,
          "qty": 7
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-06",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-06",
          "amount": 699.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-06",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-06",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-06",
          "amount": 607.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-07",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-07",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-07",
          "amount": 386.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-07",
          "amount": 905.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-07",
          "amount": 4104.00,
          "qty": 66
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-07",
          "amount": 297.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-07",
          "amount": 758.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-07",
          "amount": 705.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-07",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1361,
          "store": "南坪商场",
          "finish_date": "2022-05-07",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-07",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1365,
          "store": "大坪商场",
          "finish_date": "2022-05-07",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-07",
          "amount": 118.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-07",
          "amount": 334.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-07",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-07",
          "amount": 807.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1378,
          "store": "垫江店",
          "finish_date": "2022-05-07",
          "amount": 59.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-07",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-07",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-07",
          "amount": 98.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-08",
          "amount": 1694.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-08",
          "amount": 288.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-08",
          "amount": 2050.00,
          "qty": 20
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-08",
          "amount": 6200.00,
          "qty": 40
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-08",
          "amount": 405.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-08",
          "amount": 308.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1356,
          "store": "永川华茂店",
          "finish_date": "2022-05-08",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-08",
          "amount": 1454.00,
          "qty": 16
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-08",
          "amount": 438.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-08",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-08",
          "amount": 645.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-08",
          "amount": 407.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-08",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-08",
          "amount": 1118.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-08",
          "amount": 544.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-08",
          "amount": 238.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-08",
          "amount": 287.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-08",
          "amount": 1033.00,
          "qty": 7
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-08",
          "amount": 477.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-08",
          "amount": 1396.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-08",
          "amount": 349.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-08",
          "amount": 413.00,
          "qty": 7
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-09",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-09",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-09",
          "amount": 1176.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-09",
          "amount": 338.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-09",
          "amount": 438.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-09",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-09",
          "amount": 78.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-09",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-09",
          "amount": 877.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1372,
          "store": "江南商都",
          "finish_date": "2022-05-09",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-09",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-09",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1379,
          "store": "云阳店",
          "finish_date": "2022-05-09",
          "amount": 187.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-10",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-10",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-10",
          "amount": 967.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-10",
          "amount": 499.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-10",
          "amount": 436.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1361,
          "store": "南坪商场",
          "finish_date": "2022-05-10",
          "amount": 468.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-10",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-10",
          "amount": 537.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-10",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-10",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1372,
          "store": "江南商都",
          "finish_date": "2022-05-10",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-10",
          "amount": 456.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-10",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1378,
          "store": "垫江店",
          "finish_date": "2022-05-10",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1379,
          "store": "云阳店",
          "finish_date": "2022-05-10",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-10",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-10",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-10",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-11",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-11",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-11",
          "amount": 148.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-11",
          "amount": 118.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-11",
          "amount": 279.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-11",
          "amount": 158.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-11",
          "amount": 258.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-11",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-11",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1378,
          "store": "垫江店",
          "finish_date": "2022-05-11",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-11",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-11",
          "amount": 289.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-11",
          "amount": 137.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-12",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-12",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-12",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-12",
          "amount": 1399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1361,
          "store": "南坪商场",
          "finish_date": "2022-05-12",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-12",
          "amount": 519.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-12",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-12",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-12",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-12",
          "amount": 755.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-12",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-13",
          "amount": 119.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-13",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-13",
          "amount": 468.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-13",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-13",
          "amount": 258.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-13",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-13",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1365,
          "store": "大坪商场",
          "finish_date": "2022-05-13",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-13",
          "amount": 248.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-13",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-13",
          "amount": 215.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-14",
          "amount": 358.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-14",
          "amount": 119.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-14",
          "amount": 777.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-14",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-14",
          "amount": 138.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1356,
          "store": "永川华茂店",
          "finish_date": "2022-05-14",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-14",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-14",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1363,
          "store": "弹子石商场",
          "finish_date": "2022-05-14",
          "amount": 119.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-14",
          "amount": 477.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-14",
          "amount": 88.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-14",
          "amount": 355.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-14",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-14",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1386,
          "store": "大渡口商都",
          "finish_date": "2022-05-14",
          "amount": 118.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-14",
          "amount": 116.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-15",
          "amount": 119.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-15",
          "amount": 795.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-15",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-15",
          "amount": 378.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1361,
          "store": "南坪商场",
          "finish_date": "2022-05-15",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-15",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-15",
          "amount": 297.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-15",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1379,
          "store": "云阳店",
          "finish_date": "2022-05-15",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-15",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-15",
          "amount": 287.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-16",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-16",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-16",
          "amount": 766.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-16",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-16",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1356,
          "store": "永川华茂店",
          "finish_date": "2022-05-16",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-16",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-16",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-16",
          "amount": 479.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-16",
          "amount": 347.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-16",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1372,
          "store": "江南商都",
          "finish_date": "2022-05-16",
          "amount": 387.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-16",
          "amount": 557.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-16",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-16",
          "amount": 195.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-17",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-17",
          "amount": 237.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-17",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-17",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-17",
          "amount": 229.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-17",
          "amount": 735.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-17",
          "amount": 304.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-17",
          "amount": 119.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-17",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1386,
          "store": "大渡口商都",
          "finish_date": "2022-05-17",
          "amount": 88.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-17",
          "amount": 278.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-18",
          "amount": 499.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-18",
          "amount": 88.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1356,
          "store": "永川华茂店",
          "finish_date": "2022-05-18",
          "amount": 599.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-18",
          "amount": 599.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-18",
          "amount": 89.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-18",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-18",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-18",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1386,
          "store": "大渡口商都",
          "finish_date": "2022-05-18",
          "amount": 699.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-18",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-19",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-19",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-19",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1372,
          "store": "江南商都",
          "finish_date": "2022-05-19",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-19",
          "amount": 316.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-19",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-20",
          "amount": 329.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-20",
          "amount": 477.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-20",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-20",
          "amount": 279.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-20",
          "amount": 167.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-20",
          "amount": 218.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1365,
          "store": "大坪商场",
          "finish_date": "2022-05-20",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-20",
          "amount": 608.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-20",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-20",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-20",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-20",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-20",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-21",
          "amount": 149.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-21",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-21",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-21",
          "amount": 1329.00,
          "qty": 11
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-21",
          "amount": 1038.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1356,
          "store": "永川华茂店",
          "finish_date": "2022-05-21",
          "amount": 397.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-21",
          "amount": 349.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-21",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1361,
          "store": "南坪商场",
          "finish_date": "2022-05-21",
          "amount": 549.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-21",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-21",
          "amount": 1399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-21",
          "amount": 59.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-21",
          "amount": 716.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-21",
          "amount": 349.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1376,
          "store": "万州店",
          "finish_date": "2022-05-21",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-21",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1386,
          "store": "大渡口商都",
          "finish_date": "2022-05-21",
          "amount": 499.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-21",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-22",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-22",
          "amount": 318.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-22",
          "amount": 59.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-22",
          "amount": 247.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-22",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-22",
          "amount": 308.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-22",
          "amount": 229.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-22",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-22",
          "amount": 1014.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-22",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-22",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-22",
          "amount": 139.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-22",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-22",
          "amount": 927.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1378,
          "store": "垫江店",
          "finish_date": "2022-05-22",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1383,
          "store": "世纪新都",
          "finish_date": "2022-05-22",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1388,
          "store": "南川商都",
          "finish_date": "2022-05-22",
          "amount": 579.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-22",
          "amount": 277.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-23",
          "amount": 288.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-23",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1356,
          "store": "永川华茂店",
          "finish_date": "2022-05-23",
          "amount": 1399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-23",
          "amount": 458.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-23",
          "amount": 549.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-23",
          "amount": 627.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-23",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-23",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-23",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1382,
          "store": "潼南店",
          "finish_date": "2022-05-23",
          "amount": 529.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1386,
          "store": "大渡口商都",
          "finish_date": "2022-05-23",
          "amount": 208.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-24",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-24",
          "amount": 198.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-24",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1363,
          "store": "弹子石商场",
          "finish_date": "2022-05-24",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-24",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1365,
          "store": "大坪商场",
          "finish_date": "2022-05-24",
          "amount": 1505.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-24",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1369,
          "store": "丰都商场",
          "finish_date": "2022-05-24",
          "amount": 219.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-24",
          "amount": 318.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-24",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-24",
          "amount": 119.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-24",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1379,
          "store": "云阳店",
          "finish_date": "2022-05-24",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-24",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-24",
          "amount": 178.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-25",
          "amount": 736.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-25",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-25",
          "amount": 228.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-25",
          "amount": 237.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1356,
          "store": "永川华茂店",
          "finish_date": "2022-05-25",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-25",
          "amount": 258.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-25",
          "amount": 157.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-25",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-25",
          "amount": 807.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-25",
          "amount": 148.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1382,
          "store": "潼南店",
          "finish_date": "2022-05-25",
          "amount": 457.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-25",
          "amount": 599.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-25",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1391,
          "store": "铜梁商场",
          "finish_date": "2022-05-25",
          "amount": 388.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-26",
          "amount": 248.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-26",
          "amount": 3055.00,
          "qty": 15
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-26",
          "amount": 39.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1354,
          "store": "铜梁店",
          "finish_date": "2022-05-26",
          "amount": 258.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1357,
          "store": "长寿店",
          "finish_date": "2022-05-26",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-26",
          "amount": 88.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1361,
          "store": "南坪商场",
          "finish_date": "2022-05-26",
          "amount": 604.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-26",
          "amount": 549.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-26",
          "amount": 308.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-26",
          "amount": 268.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-26",
          "amount": 446.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1376,
          "store": "万州店",
          "finish_date": "2022-05-26",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-26",
          "amount": 447.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-26",
          "amount": 287.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1393,
          "store": "荣昌店",
          "finish_date": "2022-05-26",
          "amount": 229.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-27",
          "amount": 188.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-27",
          "amount": 449.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-27",
          "amount": 429.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1363,
          "store": "弹子石商场",
          "finish_date": "2022-05-27",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-27",
          "amount": 199.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-27",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-27",
          "amount": 678.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1371,
          "store": "新世纪綦江商场",
          "finish_date": "2022-05-27",
          "amount": 299.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-27",
          "amount": 747.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1376,
          "store": "万州店",
          "finish_date": "2022-05-27",
          "amount": 29.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-27",
          "amount": 568.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-27",
          "amount": 1158.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1385,
          "store": "万州商都",
          "finish_date": "2022-05-27",
          "amount": 749.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-27",
          "amount": 844.00,
          "qty": 6
        }, {
          "partner": "重百零售",
          "store_id": 1348,
          "store": "江北店",
          "finish_date": "2022-05-28",
          "amount": 898.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-28",
          "amount": 148.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-28",
          "amount": 349.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-28",
          "amount": 249.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1362,
          "store": "沙中心店",
          "finish_date": "2022-05-28",
          "amount": 538.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-28",
          "amount": 179.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-28",
          "amount": 1337.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-28",
          "amount": 1196.00,
          "qty": 4
        }, {
          "partner": "重百零售",
          "store_id": 1369,
          "store": "丰都商场",
          "finish_date": "2022-05-28",
          "amount": 88.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1373,
          "store": "凯瑞商都",
          "finish_date": "2022-05-28",
          "amount": 605.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1374,
          "store": "忠县店",
          "finish_date": "2022-05-28",
          "amount": 49.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1375,
          "store": "沙重百",
          "finish_date": "2022-05-28",
          "amount": 777.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1376,
          "store": "万州店",
          "finish_date": "2022-05-28",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1378,
          "store": "垫江店",
          "finish_date": "2022-05-28",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-28",
          "amount": 478.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1349,
          "store": "渝北店",
          "finish_date": "2022-05-29",
          "amount": 758.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1350,
          "store": "北碚一店",
          "finish_date": "2022-05-29",
          "amount": 447.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1351,
          "store": "北碚二店",
          "finish_date": "2022-05-29",
          "amount": 159.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1352,
          "store": "合川店",
          "finish_date": "2022-05-29",
          "amount": 2599.00,
          "qty": 11
        }, {
          "partner": "重百零售",
          "store_id": 1353,
          "store": "璧山店",
          "finish_date": "2022-05-29",
          "amount": 3145.00,
          "qty": 5
        }, {
          "partner": "重百零售",
          "store_id": 1359,
          "store": "解放碑店",
          "finish_date": "2022-05-29",
          "amount": 119.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1360,
          "store": "杨家坪商场",
          "finish_date": "2022-05-29",
          "amount": 279.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1363,
          "store": "弹子石商场",
          "finish_date": "2022-05-29",
          "amount": 129.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1364,
          "store": "西城商场",
          "finish_date": "2022-05-29",
          "amount": 428.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1366,
          "store": "綦江商场",
          "finish_date": "2022-05-29",
          "amount": 247.00,
          "qty": 3
        }, {
          "partner": "重百零售",
          "store_id": 1367,
          "store": "万盛商场",
          "finish_date": "2022-05-29",
          "amount": 99.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1368,
          "store": "江津店",
          "finish_date": "2022-05-29",
          "amount": 399.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1370,
          "store": "长寿商场",
          "finish_date": "2022-05-29",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1372,
          "store": "江南商都",
          "finish_date": "2022-05-29",
          "amount": 69.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1377,
          "store": "涪陵店",
          "finish_date": "2022-05-29",
          "amount": 379.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1380,
          "store": "梁平店",
          "finish_date": "2022-05-29",
          "amount": 229.00,
          "qty": 1
        }, {
          "partner": "重百零售",
          "store_id": 1384,
          "store": "涪陵商都",
          "finish_date": "2022-05-29",
          "amount": 498.00,
          "qty": 2
        }, {
          "partner": "重百零售",
          "store_id": 1390,
          "store": "奉节商都店",
          "finish_date": "2022-05-29",
          "amount": 1014.00,
          "qty": 6
        }]

      return data.filter(s => {
        let finishDate = new Date(s.finish_date)
        return s.partner === partner && finishDate >= startDate && finishDate < endDate
      })
    }
  },
  computed: {
    data() {
      let data = {}
      this.storeList
          .filter(s => {
            return s.enabled
          })
          .forEach(s => {

            let row = {
              name: s.Name,
              values: []
            }
            this.days.forEach(d => {

              let {qty = 0, amount = 0} = this.findValue(d, s.id) ?? {}
              row.values.push({
                value: amount,
                qty
              })
            })

            data[s.id] = row
          })
      return data
    }
  }
}
</script>

<style scoped>
.table {
  border: solid gray 1px;
  width: 1000px;
  height: 800px;
  overflow: scroll;
  margin: 10px 0;
}

.border-box {
  box-sizing: border-box;
}

.store-table {
  width: 800px;
  margin: 10px 0;
}



</style>

<style>

.target input {
  text-align: right;
}
</style>
